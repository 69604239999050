body {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  padding-top: 0;
  color: #0e3a61;
}

/* open-sans-regular - latin */

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans'), local('OpenSans'), url('../fonts/open-sans-v13-latin/open-sans-v13-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+ */
  url('../fonts/open-sans-v13-latin/open-sans-v13-latin-regular.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-600 - latin */

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local('Open Sans Semibold'), local('OpenSans-Semibold'), url('../fonts/open-sans-v13-latin/open-sans-v13-latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+ */
  url('../fonts/open-sans-v13-latin/open-sans-v13-latin-600.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-700 - latin */

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Bold'), local('OpenSans-Bold'), url('../fonts/open-sans-v13-latin/open-sans-v13-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+ */
  url('../fonts/open-sans-v13-latin/open-sans-v13-latin-700.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

a[href^='tel'] {
  color: inherit;
  text-decoration: none;
  cursor: default;
}

.logo {
  padding: 15px 0;
}

.logo-title {
  color: #0e3a61;
  float: right !important;
  font-size: 15px;
}

.lp-logo-subtitle {
  padding-top: 15px;
}

@media (max-width: 992px) {
  .logo-title {
    float: left !important;
  }
  .lp-logo-subtitle {
    padding-top: 0;
  }
}

.img-center {
  margin: 0 auto;
}

.lp-ud-body h1,
.lp-ud-body h2,
.lp-ud-body h3,
.lp-ud-body h4,
.lp-ud-body h5,
.lp-ud-body h6 {
  color: #0e3a61;
}

.btn-lg,
.btn-group-lg>.btn {
  padding: 15px 25px;
  white-space: normal;
}

.btn-primary-lp {
  background-image: linear-gradient(to bottom, #f28947 0px, #e15700 100%);
  background-repeat: repeat-x;
  border: none;
  color: #fff;
  /*box-shadow: 5px 5px 5px #808080;*/
  background-color: transparent;
  font-weight: 600;
}

.btn-primary-lp:hover,
.btn-primary-lp:focus {
  background-image: linear-gradient(to bottom, #27f018 0px, #1b9112 100%);
  background-repeat: repeat-x;
  border-color: #104a0c;
  background-color: #1b9112;
  color: #fff;
}

.img-center {
  margin: 0 auto;
}

.lp-logo-subtitle {
  color: #fff;
}

.logo img {
  width: 100%;
}

.lp-shooter {
  /*background-image: url(../bilder/gastherme/gastherme-shooter-background3.jpg);
    */
  background: #fff;
  background-image: url(../img/klimaanlage-shooter-background.png);
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: cover;
  color: #0e3a61;
}

.lp-shooter h1 {
  font-size: 6.25rem;
  font-weight: 700;
  margin-top: 3.75rem;
}

.lp-shooter h1,
.lp-shooter h2,
.lp-shooter h3,
.lp-shooter h4,
.lp-shooter h5,
.lp-shooter h6 {
  color: #f5f5f5;
}

.tab-content {
  margin-bottom: 20px;
}

ul.lp-hersteller-bilder li img {
  float: left;
}

.lp-bilder img {
  display: block;
  margin: 0 auto;
}

.lp-wizard h3 {
  color: #337ab7;
  font-weight: 700;
}

.lp-wizard .progress {
  height: 30px;
}

.lp-wizard .progress-bar {
  line-height: 28px;
}

.lp-wizard .progress-bar-info {
  background-color: #337ab7;
}

.hersteller-bild,
.personen-bild,
.flaeche-bild,
.warmwasser-bild,
.fenster-bild {
  opacity: 0.3;
  margin: 5px;
}

.hersteller-bild.clicked,
.personen-bild.clicked,
.flaeche-bild.clicked,
.warmwasser-bild.clicked,
.fenster-bild.clicked {
  opacity: 1;
}

.nav-pills>li>a,
#mein-produkt-tabs>li>a,
.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn.focus:active,
.btn:active:focus,
.btn:focus {
  outline: 0;
}

.nav-pills>li>a:hover,
.nav-pills>li>a:active,
.nav-pills>li>a:focus {
  background-color: transparent;
  color: #337ab7;
}

.page-header {
  border-bottom: 1px solid #cdcdcd;
}

@media (max-width: 375px) {
  .lp-shooter h1 {
    font-size: 4.25rem;
  }
  #hersteller-bilder img,
  #personen-bilder img,
  #flaeche-bilder img,
  #warmwasser-bilder img {
    width: 120px;
  }
  .lp-xs-link-gastherme {
    font-size: 1.5rem;
  }
  .btn-lg {
    font-size: 1em;
  }
  #step4 h3 {
    font-size: 21px;
  }
}

/* CONTENT */

#lp-schritte .thumbnail {
  border: none;
}

#lp-schritte h2,
#lp-unidomo-vorteile h2,
#lp-fragen-antworten h2 {
  font-weight: 600;
}

#lp-schritte .label {
  font-size: 100%;
}

.lp-schritte-beschr li {
  font-weight: 700;
}

.lp-schritte-beschr li p {
  font-weight: 500;
}

.lp-ud-h2-icon {
  font-size: 30px;
  margin-top: 15px;
}

.caption.lp-ud-caption {
  color: #0e3a61;
}

@media (max-width: 767px) {
  .lp-ud-h2-icon {
    float: left;
  }
  .lp-ud-caption {
    margin-left: 65px;
    margin-top: -20px;
  }
}

#lp-unidomo-vorteile {
  /*background-color: #F5F5F5;*/
}

#lp-unidomo-vorteile .panel-ud {
  border-color: transparent;
  border: none;
  box-shadow: none;
}

@media (max-width: 767px) {
  #lp-unidomo-vorteile .panel-ud {
    margin-bottom: 0;
  }
}

#lp-unidomo-vorteile .panel-ud>.panel-heading {
  color: #337ab7;
  background-color: transparent;
  border-color: transparent;
}

#lp-unidomo-vorteile div.panel-title {
  font-size: 1.3em;
}

#lp-unidomo-vorteile .panel-default>.panel-heading {
  background-color: #337ab7;
}

.small-tel {
  font-size: 80%;
}

.wells-primary {
  background-color: #337ab7;
  color: #fff;
}

.opacity-half {
  opacity: 0.5;
}

.wells-info {
  background-color: #d9edf7;
}

.lp-button-top {
  padding: 1em 0;
  margin: 0;
}

#lp-fragen-antworten h3 {
  font-weight: bold;
}

#lp-produkte {
  display: block;
}

/* Ergebnisseite */

.lp-ud-panel {
  color: #337ab7;
}

.lp-produkt-title {
  font-size: 30px;
  font-weight: 700;
  margin-top: 0;
  color: #337ab7;
}

p.lp-ud-panel-text {
  font-size: 1em;
  padding-left: 10px;
}

@media (max-width: 767px) {
  .lp-ud-panel-body {
    padding: 0;
  }
}

.lp-ud-panel-body ul {
  padding-left: 10px;
}

.lp-ud-panel-body ul li {
  font-size: 1.125em;
  font-weight: 700;
  border-bottom: 1px solid #ebeaea;
  padding: 0.375em 0px;
  list-style: none;
}

.lp-ud-panel-body .img-padding-bottom {
  margin: 1.25em 0;
}

.lp-ud-empfelung-title {
  font-weight: 600;
}

.lp-ud-empfelung-title img {
  width: 250px;
  margin-top: -5px;
}

/* lp-ud-Panel-img-text */

.lp-ud-panel-imgtext {
  display: table;
  margin: 15px 0;
}

.lp-ud-panel-imgtext-img {
  width: 50%;
  display: table-cell;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
}

.lp-ud-panel-imgtext-text {
  color: #fff;
  padding: 30px;
  line-height: 1.8em;
  background-color: #337ab7;
  display: table-cell;
}

.lp-ud-panel-imgtext-mehrtext {
  color: #fff;
  padding: 30px;
  line-height: 1.8em;
  display: none;
  background-color: #5bc0de;
}

/* Video */

.embed-responsive-16by9 {
  padding: 0;
}

/* Beratung */

.lp-ud-panel-body-beratung h3,
.lp-ud-panel-body-beratung h4,
.lp-ud-panel-body-beratung p {
  color: #337ab7;
}

.lp-ud-panel-body-beratung h3,
.lp-ud-panel-body-beratung h4 {
  font-weight: 600;
}

/*Fragen Collapse*/

.ud-lp-panel-frage {
  border-radius: 0;
  border-color: transparent;
  border: none;
  background-color: #0e3a61;
  color: #fff;
}

.panel-default>.ud-lp-panel-heading-frage {
  color: #fff;
  background-color: #337ab7;
  border-color: transparent;
  border-radius: 0;
}

@media (max-width: 767px) {
  .panel-default>.ud-lp-panel-heading-frage .panel-title h3 {
    font-size: 20px;
  }
}

.panel-default>.ud-lp-panel-heading-frage .panel-title a:hover,
.panel-default>.ud-lp-panel-heading-frage .panel-title a:focus {
  text-decoration: none;
}

.panel-default>.panel-heading+.panel-collapse>.ud-lp-panel-body-frage {
  border: none;
}

/* Footer Links */

p.lp-quellenlinks {
  cursor: pointer;
}

p.lp-quellenlinks[data-target='#modal-impressum'] {
  float: left;
}

p.lp-quellenlinks[data-target='#modal-datenschutz'] {
  float: right;
}

.ergeb-phone {
  font-size: 25px;
}

footer {
  text-align: center;
}

.lp-quellenlinks {
  font-size: 12px;
}

.embed-responsive {
  overflow: visible;
}

.share_button {
  border: none;
  font-size: 1em;
  color: #fff;
  padding: 5px;
}

.share_button i {
  font-size: 1.3em;
}

.share_button[data-sharer='twitter'] {
  background-color: #00aced;
}

.share_button[data-sharer='facebook'] {
  background-color: #3b5998;
}

.share_button[data-sharer='googleplus'] {
  background-color: #dd4b39;
}

.share_button[data-sharer='whatsapp'] {
  background-color: #29a628;
}

.share_button_xs {
  width: 3em;
  height: 3em;
}

h4 {
  font-size: 25px;
  font-weight: 0;
}

.lp-hersteller {
  &-logo {
    width: 200px;
  }
}

.lp-berater {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  img {
    border-radius: 50%;
  }
  h4 {
    padding: 1em;
  }
}